import { useEffect, useRef, useState } from "react";
import RightModal from "../components/RightModal";
import FormGroup from "../components/FormGroup";
import { useGlobalContext } from "../contexts/GlobalContext";

import { apiPatch, apiPost } from "../inc/ApiHelpers";
import { getPostsPerPage } from "../inc/Helpers";

function NewRecordForm({
	isVisible,
	setData,
	resetForm,
	defaultFormData,
	emptyFormData,
	statuses,
}) {
	const modalRef = useRef(null);

	const {
		setIsPageLoading,
		updateAnimationState,
		paginationData,
		setShowNewFormModal,
		defaultBrandSuggestions,
		defaultModelSuggestions,
	} = useGlobalContext();

	const [errors, setErrors] = useState();
	const [formData, setFormData] = useState(defaultFormData);

	// update form data on state change.
	useEffect(() => {
		setFormData(defaultFormData);
	}, [defaultFormData]);

	const today = new Date().toISOString().substring(0, 10);

	// save form's input field's values into state
	const updateFormStates = (key, val) => {
		setFormData(() => ({ ...formData, [key]: val }));
	};

	// create new or update old records.
	const handleFormSubmit = async (e) => {
		e.preventDefault();
		setErrors("");

		// prepare formdata object for sending with ajax requests.
		let tempDefaultFormData = emptyFormData;

		Object.keys(tempDefaultFormData).forEach((key) => {
			if (key === "status_id") {
				tempDefaultFormData[key] = formData?.status_id
					? formData.status_id
					: formData?.statuses?.id;
			} else {
				tempDefaultFormData[key] = formData[key];
			}
		});

		const limitTotalPosts = getPostsPerPage(paginationData);

		setIsPageLoading(true);

		try {
			let newData;

			// -----------------------------------
			// EDIT
			// -----------------------------------
			if (tempDefaultFormData?.id) {
				//  edit.

				newData = await apiPatch({
					url: "/api/records/" + tempDefaultFormData.id,
					...tempDefaultFormData,
				});

				updateAnimationState({
					id: tempDefaultFormData.id,
					action: "edit",
					callback: () => {
						//update data
						setData((prev) =>
							prev.map((item) =>
								item.id === tempDefaultFormData.id ? newData.data : item
							)
						);
					},
				});
			} else {
				// -----------------------------------
				// NEW POST
				// -----------------------------------

				newData = await apiPost({
					url: "/api/records",
					...tempDefaultFormData,
				});

				updateAnimationState({
					id: newData.data.id,
					action: "new",
					callback: () => {
						// update data
						setData((prev) =>
							[newData.data, ...prev].splice(0, limitTotalPosts)
						);
					},
				});
			}

			setShowNewFormModal(false);
		} catch (e) {
			setErrors(e.response.data.errors);
		}

		setIsPageLoading(false);
	};

	const handleBrandOnChange = (text) => {
		if (!text) {
			return [];
		}
		return defaultBrandSuggestions
			.filter(
				(suggestion) =>
					suggestion.toLowerCase().indexOf(text.toLowerCase()) > -1
			)
			.slice(0, 8);
	};

	const handleModelOnChange = async (text) => {
		if (!text) {
			return [];
		}
		return defaultModelSuggestions
			.filter(
				(suggestion) =>
					suggestion.toLowerCase().indexOf(text.toLowerCase()) > -1
			)
			.slice(0, 8);
	};

	return (
		<>
			<RightModal
				errors={errors}
				setErrors={setErrors}
				handleSubmit={handleFormSubmit}
				ref={modalRef}
				isVisible={isVisible}
				isEdit={defaultFormData?.id ? true : false}
				resetFormData={resetForm}>
				<FormGroup
					item={{
						name: "Client ",
						type: "legend",
					}}
				/>

				<div className='md:flex md:justify-between'>
					{[
						{
							id: "name",
							type: "text",
							required: 1,
							labelclass: "md:w-full  md:mb-1 md:mr-0",
							inputclass: "md:w-full",
							containerclass: "md:flex-col md:mr-6 md:mb-2 md:flex-1 ",
						},
						{
							id: "phone",
							type: "text",
							required: 1,
							labelclass: "md:w-full mr-0 md:mb-1 md:mr-0",
							inputclass: "md:w-full",
							containerclass: "md:flex-col  md:mb-2 ",
						},
					].map((item) => (
						<FormGroup
							key={item.id}
							onChange={updateFormStates}
							item={item}
							defaultFormData={defaultFormData}
							errors={errors}
							value={formData[item.id]}
						/>
					))}
				</div>

				{[
					{
						id: "address",
						type: "textarea",
						labelclass: "w-full mr-0 md:mb-1 text-left ",
						inputclass: "w-full",
						containerclass: "md:flex-col md:flex-1 md:mt-2 md:items-start",
					},
				].map((item) => (
					<FormGroup
						key={item.id}
						onChange={updateFormStates}
						item={item}
						defaultFormData={defaultFormData}
						errors={errors}
						value={formData[item.id]}
					/>
				))}

				<FormGroup item={{ name: "Device", type: "legend" }} />

				<div className='md:flex md:justify-between '>
					{[
						{
							id: "brand",
							type: "autocomplete",
							required: 1,
							suggestions: [],
							labelclass: "md:w-full mr-0 md:mb-1  md:mr-0",
							inputclass: "md:w-full",
							containerclass: "md:flex-col  md:mr-6 ",
							autocompleteOnChange: handleBrandOnChange,
						},

						{
							id: "model",
							type: "autocomplete",
							required: 1,
							labelclass: "md:w-full mr-0 md:mb-1  md:mr-0",
							inputclass: "md:w-full",
							containerclass: "md:flex-col  md:mr-6 ",
							autocompleteOnChange: handleModelOnChange,
							suggestion: [],
						},
						{
							id: "imei",
							type: "text",
							labelclass: "md:w-full mr-0 md:mb-1  md:mr-0",
							inputclass: "md:w-full min-w-[150px]",
							size: 15,
							containerclass: "md:flex-col md:flex-1  ",
						},
					].map((item) => (
						<FormGroup
							key={item.id}
							onChange={updateFormStates}
							item={item}
							defaultFormData={defaultFormData}
							errors={errors}
							value={formData[item.id]}
						/>
					))}
				</div>

				<FormGroup item={{ name: "Other", type: "legend" }} />

				<div className='md:flex md:justify-between md:mb-5 '>
					{[
						{
							id: "acceptance_date",
							type: "date",
							required: 1,
							default: today,
							labelclass: "md:w-full mr-0 md:mb-1  md:mr-0",
							inputclass: "md:w-full",
							containerclass: "md:flex-col md:mr-6",
						},
						{
							id: "repair_date",
							type: "date",
							labelclass: "md:w-full mr-0 md:mb-1  md:mr-0",
							inputclass: "md:w-full",
							containerclass: "md:flex-col md:mr-6",
						},
						{
							id: "approximate_cost",
							type: "number",
							name: "Appr. Cost",
							min: 0,
							step: "0.01",
							labelclass: "md:w-full mr-0 md:mb-1  md:mr-0",
							inputclass: "md:w-full",
							containerclass: "md:flex-col md:mr-6",
						},

						{
							id: "final_charge",
							type: "number",
							min: 0,
							step: "0.01",
							labelclass: "md:w-full mr-0 md:mb-1  md:mr-0",
							inputclass: "md:w-full",
							containerclass: "md:flex-col",
						},
					].map((item) => (
						<FormGroup
							key={item.id}
							onChange={updateFormStates}
							item={item}
							defaultFormData={defaultFormData}
							errors={errors}
							value={formData[item.id]}
						/>
					))}
				</div>

				{[
					{
						id: "status_id",
						name: "Status",
						type: "select",
						route: "status",
						required: 1,
						options: statuses
							? statuses.map((item) => {
									return { label: item.title, value: item.id };
							  })
							: [],
					},

					{
						id: "problems",
						type: "textarea",
						required: 1,
					},

					{ id: "services", type: "textarea" },
					{ id: "note", type: "textarea" },
					{ id: "id", type: "hidden" },
				].map((item) => (
					<FormGroup
						key={item.id}
						onChange={updateFormStates}
						item={item}
						defaultFormData={defaultFormData}
						errors={errors}
						value={formData[item.id]}
					/>
				))}
			</RightModal>
		</>
	);
}

export default NewRecordForm;
