import ReactSelect from "./ReactSelect";
import TextareaAutosize from "react-textarea-autosize";
import Autocomplete from "./Autocomplete";

const GetCorrectFormField = ({ data, ...props }) => {
	// extract invalid dom props
	let { searchHandler, ...otherProps } = props;

	const handleOnChange = (e) => {
		if (data.type === "file") {
			otherProps.onChange(data.id, e.target.files[0]);
		} else {
			otherProps.onChange(data.id, e.target.value);
		}
	};

	if (data.isrequired === 1) {
		otherProps = { ...otherProps, required: "required" };
	}
	if (data.type === "textarea") {
		// ---------------
		// TEXTAREA
		// ---------------

		return <TextareaAutosize {...otherProps} onChange={handleOnChange} />;
	} else if (data.type === "select") {
		// ---------------
		// REACT SELECT FIELD
		// ---------------

		return (
			<ReactSelect
				{...otherProps}
				selvalue={otherProps?.defaultformdata?.seloption}
				data={data}
			/>
		);
	} else if (data.type === "autocomplete") {
		// ---------------
		// REACT AUTO COMPLETE
		// ---------------

		const { autocompleteOnChange, autocompleteOnSelect } = data;
		const { value, onChange } = otherProps;

		return (
			<Autocomplete
				value={value}
				setValue={onChange}
				{...otherProps}
				onChange={autocompleteOnChange}
				onSelect={autocompleteOnSelect}
				{...data}
			/>
		);
	} else {
		// ---------------
		// INPUT FIELDS
		// ---------------
		let css = otherProps.className;

		if (data.type === "color") {
			// ---------------
			// STYLES FOR COLOR INPUT FIELD
			// --------------
			css = " w-[60px] h-[40px] border-0 bg-white ";
		} else if (data.type === "date") {
			// ---------------
			// STYLES FOR DATE INPUT FIELD
			// ---------------
			css = css + " w-[200px] ";
		}

		if (data.type === "file") {
			// file input field does not support "value" attr.
			delete otherProps.value;
		}

		return (
			<input
				{...otherProps}
				{...data}
				className={css}
				onChange={handleOnChange}
				autoComplete='off'
			/>
		);
	}
};

export default GetCorrectFormField;
