import { useEffect, useState } from "react";
import Table from "../components/Table";
import PageHeader from "../components/PageHeader";

import NewPurchaseForm from "./NewPurchaseForm";
import AppLayout from "../layouts/AppLayout";

import { apiGet, apiPatch, deletePost } from "../inc/ApiHelpers";
import Pagination from "../components/Pagination";
import { useSearchParams } from "react-router-dom";
import { useGlobalContext } from "../contexts/GlobalContext";
import { getData } from "../inc/Helpers";

function Purchases() {
	const arraySort = require("array-sort");

	const {
		updateAnimationState,
		setPaginationData,
		showNewFormModal,
		setShowNewFormModal,
		updatePageTitle,
		setToast,
		sortTableBy,
	} = useGlobalContext();

	const pathName = "/Purchases";

	const [data, setData] = useState();
	const [isLoading, setIsLoading] = useState(false);

	const [searchParams] = useSearchParams();

	const curPageNo = searchParams.get("page")
		? parseInt(searchParams.get("page"))
		: 1;

	const emptyFormData = {
		purchase_date: "",
		brand: "",
		model: "",
		item: "",
		rate: "",
		shop: "",
		paid_date: "",
		payment_method: "",
		id: "",
	};

	const [showVisibleColumnsDropdown, setShowVisibleColumnsDropdown] =
		useState(false);
	const [visibleTableColumns, setVisibleTableColumns] = useState({
		purchase_date: true,
		brand: true,
		model: true,
		item: true,
		rate: true,
		shop: true,
		paid_date: true,
		payment_method: true,
		id: true,
		action: true,
	});

	const [defaultFormData, setDefaultFormData] = useState(emptyFormData);

	/**
	 * It resets the form data to default values and shows the modal.
	 */
	const showEmptyFormModal = () => {
		// reset form data when clicked on green "add new " btn.
		setDefaultFormData({
			title: "",
			description: "",
			color: "",
			id: "",
		});

		// show modal.
		setShowNewFormModal(true);
	};

	/**
	 * When the edit button is clicked, we filter the data array to get the selected item, then we
	 * update the default form data with the selected item and show the modal
	 * @param id - The id of the item to be edited.
	 */
	const onEditClick = (id) => {
		const selData = data.filter((item) => item.id === id);

		// update default form data.
		setDefaultFormData(...selData);

		// show modal.
		setShowNewFormModal(true);
	};

	/**
	 * `resetForm` resets the form fields to their default values
	 */
	const resetForm = () => {
		setDefaultFormData(emptyFormData);
	};

	/**
	 * It deletes a row from the database and updates the state
	 * @param id - the id of the row to delete.
	 */
	const deleteRow = async (id) => {
		try {
			deletePost("/api/purchases/" + id);

			updateAnimationState({
				id: id,
				action: "delete",
				callback: () => {
					// update state.
					setData((prev) => prev.filter((item) => item.id !== id));
				},
			});
		} catch (e) {
			setToast(e.response.data.message, "danger");
		}
	};

	/**
	 * When the user changes the value of a checkbox, update the state of the visibleTableColumns object
	 * with the new value.
	 * @param e - the event object
	 */
	const handleVisibleColumnOptionChange = (e) => {
		setVisibleTableColumns((prev) => ({
			...prev,
			[e.target.id]: e.target.checked,
		}));

		// update in api
		apiPatch({
			url: "/api/tablesettings/update-visible-columns/purchases",
			...{
				visibleColumns: {
					...visibleTableColumns,
					[e.target.id]: e.target.checked,
				},
			},
		});
	};

	useEffect(() => {
		/* A callback function that fetches data from the server and updates the state. */
		getData(
			"/api/purchases?page=" + curPageNo,
			setIsLoading,
			apiGet,
			setData,
			setPaginationData,
			setToast
		);
	}, [curPageNo]);

	useEffect(() => {
		/* It updates the page title. */
		updatePageTitle(pathName);
	}, [pathName]);

	useEffect(() => {
		setData((prev) =>
			arraySort(prev, sortTableBy[0], {
				reverse: sortTableBy[1] === "asc",
			})
		);
	}, [sortTableBy]);

	useEffect(() => {
		apiGet("/api/tablesettings/get-visible-columns/purchases").then((res) => {
			// update visibleTableColumns state.
			setVisibleTableColumns((prev) => ({ ...prev, ...res.visibleColumns }));
		});
	}, []);

	return (
		<AppLayout>
			<div className='app-body px-3 md:px-8 pt-5 md:pt-6  print:p-0'>
				<PageHeader onClick={showEmptyFormModal} />

				<Table
					theads={[
						{ id: "id", label: "ID", sortBy: "id" },
						{
							id: "purchase_date",
							label: "Purchased",
							sortBy: "purchase_date",
						},
						"brand",
						"model",
						"item",
						"rate",
						"shop",
						{
							id: "paid_date",
							label: "Paid",
							sortBy: "paid_date",
						},
						{
							id: "payment_method",
							label: "Payment Method",
							sortBy: "payment_method",
						},
						"action",
					]}
					setShowModal={showEmptyFormModal}
					data={data}
					handleDelete={deleteRow}
					onEditClick={onEditClick}
					isLoading={isLoading}
					isSearch={false}
					showVisibleColumnsDropdown={showVisibleColumnsDropdown}
					setShowVisibleColumnsDropdown={setShowVisibleColumnsDropdown}
					handleVisibleColumnOptionChange={handleVisibleColumnOptionChange}
					visibleTableColumns={visibleTableColumns}
				/>

				<Pagination />
			</div>

			<NewPurchaseForm
				setData={setData}
				defaultFormData={defaultFormData}
				isVisible={showNewFormModal}
				resetForm={resetForm}
			/>
		</AppLayout>
	);
}

export default Purchases;
