export const getData = async (
	route,
	setIsLoading,
	apiGet,
	setData,
	setPaginationData,
	setToast
) => {
	setIsLoading(true);

	try {
		const data = await apiGet(route);

		// update data.
		setData(data.data);

		setPaginationData({ links: data.links, meta: data.meta });
	} catch (e) {
		setToast(e.response.data.message, "danger");
	}

	setIsLoading(false);
};

export const getPostsPerPage = (paginationData) => {
	return paginationData?.meta?.per_page ? paginationData?.meta?.per_page : 15;
};
