import { useRef } from "react";
import { useEffect } from "react";
import { FaPlus, FaCog } from "react-icons/fa";
import { BarLoader } from "react-spinners";
import { useGlobalContext } from "../contexts/GlobalContext";
import GreenButton from "./GreenButton";
import Td from "./tables/Td";

const Table = ({
	theads,
	data,
	isLoading,
	setShowModal,
	handleDelete,
	onEditClick,
	isSearch,
	showVisibleColumnsDropdown,
	setShowVisibleColumnsDropdown,
	handleVisibleColumnOptionChange,
	visibleTableColumns,
	...props
}) => {
	const {
		animationState,
		pageTitle,
		extractTheadId,
		sortTableBy,
		setSortTableBy,
	} = useGlobalContext();

	const visibleColumnsDropdownRef = useRef(null);

	/**
	 * If the user clicks on the column header, then toggle the sort order.
	 * @param item - the item that was clicked on
	 */
	const updateSortByState = (item) => {
		let sortOrder = "asc";

		if (sortTableBy[0] === extractTheadId(item, "sortBy")) {
			sortOrder = sortTableBy[1] === "asc" ? "desc" : "asc";
		}

		setSortTableBy([extractTheadId(item, "sortBy"), sortOrder]);
	};

	/**
	 * Should we show selected column or not?
	 * @param colId - The column id
	 * @returns The value of the visibleTableColumns[colId] property.
	 */
	const showSelColumn = (colId) => {
		return visibleTableColumns && visibleTableColumns[colId];
	};

	const showHiddenTableColumnIndicator = () => {
		let returnData = false;

		theads.map((item) => {
			let id = extractTheadId(item, "id");
			if (!visibleTableColumns[id]) {
				returnData = true;
			}
		});

		return returnData;
	};

	useEffect(() => {
		// hide modal form when clicked outside.
		function handleClickOutside(event) {
			if (
				visibleColumnsDropdownRef.current &&
				!visibleColumnsDropdownRef.current.contains(event.target)
			) {
				if (showVisibleColumnsDropdown) {
					setShowVisibleColumnsDropdown((prev) => !prev);
				}
			}
		}

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [showVisibleColumnsDropdown, visibleColumnsDropdownRef]);

	return (
		<>
			<div className='w-[100%] overflow-y-hidden overflow-x-auto bg-white'>
				<table className='text-[14px] border mt-[-1px] w-full print:text-[10px] print:border '>
					<thead>
						<tr>
							{theads.map(
								(item, index) =>
									showSelColumn(extractTheadId(item, "id")) && (
										<th
											key={extractTheadId(item, "id")}
											className={
												(index === 0 ? "w-[70px]" : "") +
												" border-b p-3 text-left bg-indigo-50  print:p-1 text-xs "
											}>
											<button
												className={
													"capitalize  after:ml-1 " +
													(sortTableBy[0] === extractTheadId(item, "sortBy")
														? sortTableBy[1] === "desc"
															? " after:content-['↓'] "
															: " after:content-['↑'] "
														: "")
												}
												onClick={() => updateSortByState(item)}>
												{/* Thead label */}
												{extractTheadId(item, "label") !== "action"
													? extractTheadId(item, "label")
													: ""}
											</button>
										</th>
									)
							)}

							<th
								className={
									" border-b p-3 text-left bg-indigo-50  print:p-1 text-xs "
								}>
								<button
									type='button'
									title='Table Settings'
									className='hidden md:block float-right mr-2 group  p-2 hover:text-white hover:bg-black rounded relative '
									onClick={() => {
										setShowVisibleColumnsDropdown((prev) => !prev);
									}}>
									{showHiddenTableColumnIndicator() && (
										<div
											className={
												"bg-red-500 w-[10px] h-[10px] rounded-full absolute right-[3px] top-[3px]  border-2 border-indigo-50 group-hover:border-black"
											}></div>
									)}

									<FaCog />
								</button>

								{showVisibleColumnsDropdown && (
									<div
										className={
											"visible-columns-dropdown-container hidden md:block"
										}>
										<div className='bg-black fixed top-0 right-0 bottom-0 left-0 bg-opacity-10 z-[9999]'></div>

										<div
											className=' absolute bg-white right-[32px] mt-[43px] font-normal  rounded-b z-[99999]'
											ref={visibleColumnsDropdownRef}>
											<div className='font-bold  bg-orange-200 p-3 py-2'>
												Visible Columns
											</div>
											<ul className='px-3 py-1 '>
												{theads.map(
													(item) =>
														!["id", "action"].includes(
															extractTheadId(item, "id")
														) && (
															<li key={extractTheadId(item, "id")}>
																<label
																	htmlFor={extractTheadId(item, "id")}
																	className='flex items-center my-2 capitalize leading-[1]'>
																	<input
																		type='checkbox'
																		className='mr-2'
																		id={extractTheadId(item, "id")}
																		onChange={handleVisibleColumnOptionChange}
																		checked={showSelColumn(
																			extractTheadId(item, "id")
																		)}
																	/>
																	{extractTheadId(item, "label")}
																</label>
															</li>
														)
												)}
											</ul>
										</div>
									</div>
								)}
							</th>
						</tr>
					</thead>
					<tbody>
						<>
							{isLoading && (
								<tr>
									<td colSpan={theads.length}>
										<div className='flex h-[100px] text-center items-center w-[100vw] md:w-full'>
											<BarLoader
												loading={isLoading}
												color='#469dee'
												cssOverride={{
													display: "block",
													margin: "0 auto",
												}}
												size={50}
											/>
										</div>
									</td>
								</tr>
							)}

							{!isLoading && data && data.length > 0 && (
								<>
									{/* show data automatically based on thead names */}
									{/* db field name and thead name must match */}
									{Object.values(data).map((client, index) => (
										<tr
											key={client.id + "-" + index}
											className='hover:bg-gray-50'>
											{theads.map(
												(k, i) =>
													showSelColumn(extractTheadId(k, "id")) && (
														<Td
															key={i}
															fieldName={extractTheadId(k, "id")}
															colIndex={i}
															rowIndex={index}
															allData={client}
															handleDelete={handleDelete}
															onEditClick={onEditClick}
															animationState={animationState}
															{...props}
														/>
													)
											)}
										</tr>
									))}
								</>
							)}

							{/* no results found */}
							{!isLoading && !isSearch && (!data || data.length < 1) && (
								<tr>
									<td colSpan={theads.length}>
										<div className='text-center px-3 py-[50px] '>
											<p className='mb-4 text-lg'>Nothing to show right now</p>

											<GreenButton onClick={() => setShowModal(true)}>
												<FaPlus />
												<span className='ml-2 capitalize'>
													Add New{" "}
													<span className='capitalize'>{pageTitle}</span>
												</span>{" "}
											</GreenButton>
										</div>
									</td>
								</tr>
							)}

							{!isLoading && isSearch && (!data || data.length < 1) && (
								<tr>
									<td colSpan={theads.length}>
										<div className='text-center px-3 py-3 '>
											0 results found.
										</div>
									</td>
								</tr>
							)}
						</>
					</tbody>
				</table>
			</div>
		</>
	);
};

export default Table;
