import { useGlobalContext } from "../contexts/GlobalContext";
import LoadingSvg from "./LoadingSvg";

const AnimatedButton = ({ type, onClick, label, cssClass }) => {
	const { isPageLoading } = useGlobalContext();

	return (
		<button
			type={type}
			onClick={onClick}
			className={
				(isPageLoading ? "bg-green-500 border-green-600 text-white " : "") +
				"border  font-bold hover:bg-green-600 hover:border-green-600 hover:text-white text-sm px-4 py-2  rounded  flex items-center justify-center min-w-[150px] " +
				cssClass
			}>
			{isPageLoading && <LoadingSvg />}

			{!isPageLoading && label}
		</button>
	);
};

export default AnimatedButton;
