import GetCorrectFormField from "./forms/GetCorrectFormField";

const FormGroup = ({ item, value, onChange, errors, defaultFormData }) => {
	const item_name = item.id;

	if (item.type === "legend") {
		return (
			<div className={"mt-8 mb-3 first:mt-0 text-gray-400 capitalize "}>
				{item?.name && item.name}
				{!item?.name && item.id.replace("_", " ")}
			</div>
		);
	}

	return (
		<div
			className={
				item.type === "hidden"
					? "hidden"
					: "flex items-center  mb-2 " + item.containerclass
			}>
			{item.type !== "hidden" && (
				<label
					htmlFor={item.id}
					className={"md:mr-4 w-[170px]  capitalize " + item?.labelclass}>
					{item?.name && item.name}
					{!item?.name && item.id.replaceAll("_", " ")}
					{item.required ? <span className='text-red-600 ml-1'>*</span> : ""}
				</label>
			)}

			<div className='w-full'>
				<GetCorrectFormField
					data={item}
					value={value ? value : item?.default ? item.default : ""}
					onChange={onChange}
					defaultformdata={defaultFormData}
					options={item?.options ? item.options : null}
					searchHandler={item?.searchHandler ? item.searchHandler : () => {}}
					className={
						"w-full p-2 px-3 bg-white focus:outline-none border border-gray-200 rounded-sm text-gray-600 focus:border-blue-300 " +
						(errors && errors.hasOwnProperty(item.id) ? "border-red-400" : "") +
						item?.inputclass
					}
				/>

				{errors && errors[item_name] && (
					<div className='text-red-600 mb-2'>
						<ul>
							{errors[item_name].map((i, index) => (
								<li key={index}>{i}</li>
							))}
						</ul>
					</div>
				)}
			</div>
		</div>
	);
};

export default FormGroup;
