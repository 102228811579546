import axios from "./axios";

// GET Request.
export const apiGet = async (url) => {
	return await axios.get(url).then((res) => res.data);
};

// POST Request
export const apiPost = async ({ url, ...props }) => {
	return await axios.get("/sanctum/csrf-cookie").then(async () => {
		return await axios.post(url, props).then((res) => res.data);
	});
};

// PATCH Request.
export const apiPatch = async ({ url, ...props }) => {
	return await axios.get("/sanctum/csrf-cookie").then(async () => {
		return await axios.patch(url, props).then((res) => res.data);
	});
};

// DELETE Request
export const deletePost = async (url) => {
	return await axios.delete(url).then((res) => res.data);
};
