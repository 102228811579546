import { FaPlus } from "react-icons/fa";
import { useGlobalContext } from "../contexts/GlobalContext";
import GreenButton from "./GreenButton";

const PageHeader = ({ onClick }) => {
	const { pageTitle } = useGlobalContext();
	let id = pageTitle;

	if (!id) return <></>;
	return (
		<>
			<div className='flex justify-between items-center mb-6 print:mb-2'>
				<div>
					<h2 className='text-[30px] md:my-4 md:text-[50px]  md:font-light flex-1 leading-[1] capitalize print:text-sm '>
						{id}
						{id.slice(-1) === "s" ? "es" : "s"}
					</h2>
				</div>

				<GreenButton onClick={() => onClick(true)}>
					<FaPlus />
					<span className='ml-2 capitalize hidden md:inline-block'>
						Add New {id}
					</span>
				</GreenButton>
			</div>
		</>
	);
};

export default PageHeader;
