import useSWR from "swr";
import axios from "../inc/axios";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const useAuth = ({ middleware, redirectIfAuthenticated } = {}) => {
	const navigate = useNavigate();

	const params = useParams();

	const {
		data: user,
		error,
		mutate,
	} = useSWR("/api/user", () =>
		axios
			.get("/api/user")
			.then((res) => res.data)
			.catch((error) => {
				if (error.response.status !== 409) throw error;
			})
	);

	const csrf = () => axios.get("/sanctum/csrf-cookie");

	const login = async ({ setErrors, ...props }) => {
		await csrf();

		setErrors("");

		await axios
			.post("/login", props)
			.then(() => mutate())
			.catch((error) => {
				// if (error.response.status !== 422) throw error;
				// else if (error.response.status !== 429) setErrors('Too')
				setErrors(error.response.data.message);
			});
	};

	const forgotPassword = async ({ setErrors, setEmailSent, email }) => {
		await csrf();

		await axios
			.post("/forgot-password", { email })
			.then(() => setEmailSent(true))
			.catch((error) => {
				if (error.response.status !== 422) throw error;
				setErrors(error.response.data.message);
			});
	};

	const resetPassword = async ({ setErrors, setStatus, ...props }) => {
		await csrf();

		axios
			.post("/reset-password", { token: params["*"], ...props })
			.then((response) => setStatus(response.data.status))
			.catch((error) => {
				if (error.response.status !== 422) throw error;
				setErrors(error.response.data.message);
			});
	};

	const resendEmailVerification = () => {
		axios.post("/email/verification-notification");
	};

	const logout = async () => {
		if (!error) {
			await axios.post("/logout").then(() => mutate());
		}

		window.location.pathname = "/";
	};

	useEffect(() => {
		if (middleware === "guest" && redirectIfAuthenticated && user) {
			navigate(redirectIfAuthenticated);
		}

		if (
			window.location.pathname === "/verify-email" &&
			user?.email_verified_at
		) {
			navigate(redirectIfAuthenticated);
		}

		if (middleware === "auth" && ((user && user.length < 1) || error)) {
			logout();
		}
	}, [user, error]);

	return {
		user,
		login,
		error,
		forgotPassword,
		resetPassword,
		resendEmailVerification,
		logout,
	};
};
