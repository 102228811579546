import { useEffect, useState } from "react";
import AnimatedButton from "../components/AnimatedButton";
import { useGlobalContext } from "../contexts/GlobalContext";
import AppLayout from "../layouts/AppLayout";
import { useAuth } from "../hooks/auth";
import { apiGet, apiPatch } from "../inc/ApiHelpers";
import axios from "../inc/axios";
import Profile from "./settings/Profile";
import Organization from "./settings/Organization";
import Option from "./settings/Option";

function Settings() {
	const { user } = useAuth({ middleware: "auth" });
	const emptyProfileFormData = {
		name: "",
		email: "",
		password: "",
		password_confirmation: "",
		id: "",
	};

	const emptyOptionsFormData = {
		default_status_in_records_page: "",
		termsandconditions: "",
		id_prefix: "",
		posts_per_page: "",
	};

	const emptyOrganizationFormData = {
		name: "",
		phone: "",
		logo: "",
		address: "",
		country: "",
		id: "",
	};

	const [errors, setErrors] = useState();
	const [profileFormData, setProfileFormData] = useState(emptyProfileFormData);
	const [optionsFormData, setOptionsFormData] = useState(emptyOptionsFormData);
	const [organizationFormData, setOrganizationFormData] = useState(
		emptyOrganizationFormData
	);

	const { setIsPageLoading, setToast } = useGlobalContext();
	const [statuses, setStatuses] = useState([]);
	const [selTab, setSelTab] = useState("organization");

	// create new or update old records.
	const handleFormSubmit = async (e) => {
		e.preventDefault();
		setErrors("");

		setIsPageLoading(true);

		// api request.
		try {
			if (selTab === "options") {
				await apiPatch({
					url: "/api/update-organization-settings/" + user?.id,
					...{ options: optionsFormData },
				});

				// update state
				setOptionsFormData((prev) => ({
					...prev,
					...optionsFormData,
				}));
			} else if (selTab === "organization") {
				var formData = new FormData();

				if (organizationFormData.logo) {
					formData.append("logo", organizationFormData.logo);
				}

				formData.append("_method", "PATCH");

				Object.keys(organizationFormData).forEach((key) => {
					if (key !== "logo") {
						formData.append(key, organizationFormData[key]);
					}
				});

				await axios.get("/sanctum/csrf-cookie").then(async () => {
					await axios.post(
						"/api/update-organization-data/" + user?.id,
						formData
					);
				});

				// refresh window.
				window.location.reload();
			}

			setToast("Settings is saved", "success");
		} catch (e) {
			setErrors(e.response.data.errors);
		}

		setIsPageLoading(false);
	};

	useEffect(() => {
		// get fresh user data.
		apiGet("/api/user?getalldata=1").then((freshUserData) => {
			// PROFILE
			let tempData = emptyProfileFormData;
			Object.keys(tempData).forEach((key) => {
				tempData[key] = freshUserData[key];
			});

			setProfileFormData((prev) => ({ ...tempData, ...prev }));

			// ORGANIZATION
			tempData = emptyOrganizationFormData;
			Object.keys(tempData).forEach((key) => {
				if (key !== "logo") {
					tempData[key] = freshUserData.organization[key];
				}
			});

			setOrganizationFormData((prev) => ({
				...tempData,
				...prev,
			}));

			// OPTIONS
			tempData = emptyOptionsFormData;
			Object.keys(tempData).forEach((key) => {
				tempData[key] = freshUserData?.organization?.settings
					? freshUserData.organization.settings[key]
					: "";
			});

			setOptionsFormData((prev) => ({
				...tempData,
				...prev,
			}));

			// get all statuses

			try {
				apiGet("/api/status").then((res) => {
					setStatuses(res.data);
				});
			} catch (e) {
				setToast(e.response.data.message, "danger");
			}
		});
	}, []);

	return (
		<AppLayout>
			<div className='app-body px-3 pt-4 md:px-8 md:pt-6'>
				<h2 className=' text-[36px] md:text-[50px] font-light flex-1 leading-0 capitalize mb-3 md:mb-5'>
					Settings
				</h2>

				<ul className='tabs flex mb-8 '>
					{["organization", "options"].map((item) => (
						<li
							key={item}
							className={
								"border bg-white rounded mr-1  md:mr-2  mb-[-1px] text-sm  " +
								(selTab === item
									? "bg-gray-700 border-gray-800 text-white font-bold"
									: "hover:bg-blue-100 border-blue-200")
							}>
							<button
								onClick={() => setSelTab(item)}
								className='capitalize px-4 py-2 md:px-6'>
								{item}
							</button>
						</li>
					))}
				</ul>

				<div className='tabs-content'>
					<form
						method='POST'
						onSubmit={handleFormSubmit}
						className=' mb-[35px]'>
						<Organization
							selTab={selTab}
							setOrganizationFormData={setOrganizationFormData}
							organizationFormData={organizationFormData}
							errors={errors}
						/>

						{statuses.length > 0 ? (
							<Option
								selTab={selTab}
								optionsFormData={optionsFormData}
								setOptionsFormData={setOptionsFormData}
								statuses={statuses}
								errors={errors}
							/>
						) : (
							<div>Please add some Status to view options page</div>
						)}

						<AnimatedButton
							type='submit'
							label='Save'
							cssClass='bg-green-500 border-green-500 text-white min-w-[200px] mt-[35px]'
						/>
					</form>
				</div>
			</div>
		</AppLayout>
	);
}

export default Settings;
