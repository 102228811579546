import { toast } from "react-toastify";
import { createContext, useContext, useEffect, useState } from "react";

import defaultMobileBrands from "../data/brands";
import defaultMobileModels from "../data/models";
import { apiGet } from "../inc/ApiHelpers";

export const GlobalContext = createContext();
export const GlobalContextProvider = ({ children }) => {
	const [isPageLoading, setIsPageLoading] = useState(false);
	const [animationState, setAnimationState] = useState();
	const [paginationData, setPaginationData] = useState();
	const [showNewFormModal, setShowNewFormModal] = useState(false);
	const [pageTitle, setPageTitle] = useState();
	const [sortTableBy, setSortTableBy] = useState(["id", "desc"]);

	const [defaultBrandSuggestions, setDefaultBrandSuggestions] = useState(
		defaultMobileBrands()
	);
	const [defaultModelSuggestions, setDefaultModelSuggestions] = useState(
		defaultMobileModels()
	);

	const [modelsAndBrandsisFetched, setModelsAndBrandsisFetched] =
		useState(false);

	useEffect(() => {
		if (pageTitle) {
			if (["record", "purchase"].includes(pageTitle.toLowerCase())) {
				if (!modelsAndBrandsisFetched) {
					// cache, top few most used brands
					apiGet("/api/get-popular-brands").then((res) =>
						setDefaultBrandSuggestions((prev) => [
							...new Set([...prev, ...res]),
						])
					);

					// cache, top few most used models of any brands.
					apiGet("/api/get-popular-models").then((res) =>
						setDefaultModelSuggestions((prev) => [
							...new Set([...prev, ...res]),
						])
					);

					setModelsAndBrandsisFetched(true);
				}
			}
		}
	}, [pageTitle]);

	const setToast = (msg, status = null) => {
		const options = {
			position: "bottom-left",
			autoClose: 6000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
		};

		if (status === "success") {
			toast.success(msg, options);
		} else if (status === "warning") {
			toast.warn(msg, options);
		} else if (status === "danger") {
			toast.error(msg, options);
		} else if (status === "info") {
			toast.info(msg, options);
		} else {
			toast(msg, options);
		}
	};

	const updateAnimationState = async ({ action, id, callback }) => {
		if (action === "delete") {
			setAnimationState({
				action: action,
				id: id,
			});

			await new Promise((res) => setTimeout(res, 300));

			// trigger callback aactions
			callback();
		} else {
			// trigger callback actions
			callback();

			setAnimationState({
				action: action,
				id: id,
			});

			await new Promise((res) => setTimeout(res, 1000));
		}
		setAnimationState();
	};

	const updatePageTitle = (title) => {
		title = title.replace("/", "");
		title = title === "status" ? title : title.slice(0, -1);
		setPageTitle(title);
	};

	const getPlural = (word) => {
		return word + (word.slice(-1) === "s" ? "" : "s");
	};

	const hasPermission = (permissionRequired, user) => {
		if (Array.isArray(permissionRequired)) {
			if (permissionRequired.includes(user?.role?.name.toLowerCase()))
				return true;
		} else {
			if (permissionRequired === user?.role?.name.toLowerCase()) return true;
		}
		return false;
	};

	const extractTheadId = (id, returnType = "id") => {
		if (typeof id === "object") {
			return id[returnType];
		}
		if (id.indexOf("::") > -1) {
			const ar = id.split("::");
			return returnType === "id"
				? ar[0]
				: ar[1]
				? ar[1].replaceAll("_", " ")
				: ar[1];
		}

		return id;
	};

	return (
		<GlobalContext.Provider
			value={{
				isPageLoading,
				setIsPageLoading,
				setToast,
				updateAnimationState,
				animationState,
				paginationData,
				setPaginationData,
				showNewFormModal,
				setShowNewFormModal,
				updatePageTitle,
				pageTitle,
				getPlural,
				hasPermission,
				extractTheadId,
				sortTableBy,
				setSortTableBy,
				defaultBrandSuggestions,
				defaultModelSuggestions,
			}}>
			{children}
		</GlobalContext.Provider>
	);
};

export const useGlobalContext = () => {
	return useContext(GlobalContext);
};
