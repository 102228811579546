import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTransition } from "@react-spring/web";
import { useAuth } from "../hooks/auth";
import UserDropDownMenu from "./headers/UserDropDownMenu";
import { useGlobalContext } from "../contexts/GlobalContext";
import Gravatar from "react-gravatar";

const Header = () => {
	let location = useLocation();

	const { user, logout } = useAuth({ middleware: "guest" });
	const { hasPermission } = useGlobalContext();

	const [showUserMenu, setShowUserMenu] = useState(false);

	const userMenuRef = useRef(null);

	const displayName =
		user?.name !== undefined && user?.name !== null
			? user.name
			: "Hello Mobile Care";

	useEffect(() => {
		// hide user menu when clicked outside.
		function handleClickOutside(event) {
			if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
				setShowUserMenu(false);
			}
		}

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [userMenuRef]);

	const transitions = useTransition(showUserMenu, {
		from: { opacity: 0, y: 24 },
		enter: { opacity: 1, y: 0 },
		leave: { opacity: 0, y: 24 },
	});

	if (location.pathname === "/") return <></>;

	return (
		<header className='header sticky top-0 bg-white shadow-md shadow-gray-200 flex items-center justify-between px-1 md:px-8 py-02 print:hidden z-[9999]'>
			<h1 className='w-3/12 font-bold text-md hidden md:block '>
				<img
					src={
						user?.organization?.logo
							? process.env.REACT_APP_API_URL +
							  "/images/" +
							  user.organization.logo
							: "/icon/default.png"
					}
					alt={user?.organization?.name}
					className='h-[40px]'
				/>
			</h1>
			<nav className='nav  text-md'>
				<ul className='flex items-center'>
					{[
						{ title: "Records", url: "/records" },
						{ title: "Purchases", url: "/purchases" },
						{ title: "Status", url: "/status" },
						{ title: "Users", url: "/users" },
					].map((item) => (
						<li
							key={item.title}
							className={
								"px-1 md:px-0 " +
								(item.title === "Users" &&
								hasPermission(["staff", "manager"], user)
									? " hidden "
									: "  ")
							}>
							<NavLink
								to={item.url}
								className={({ isActive }) =>
									" hover:text-green-500 hover:border-green-500 p-4 px-1 md:px-4 border-b-2 border-green-500/0  duration-200 cursor-pointer block " +
									(isActive
										? " text-green-500 border-green-500/100 font-semibold "
										: "")
								}>
								{item.title}
							</NavLink>
						</li>
					))}
				</ul>
			</nav>

			<div className='w-3/12 text-sm relative flex justify-end '>
				<div ref={userMenuRef}>
					{user && (
						<button
							className='flex justify-end items-center mr-1'
							onClick={() => setShowUserMenu(!showUserMenu)}>
							<Gravatar
								email={user?.email}
								size={32}
								default='mp'
								className='w-[32px] rounded-full mr-2'
							/>

							<span className='capitalize hidden md:block'>{displayName}</span>
						</button>
					)}

					<>
						{user && (
							<>
								{transitions((styles, item) =>
									item ? (
										<UserDropDownMenu
											styles={styles}
											user={user}
											logout={logout}
										/>
									) : (
										""
									)
								)}
							</>
						)}
					</>
				</div>
			</div>
		</header>
	);
};

export default Header;
