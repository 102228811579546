import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import LoadingSvg from "../components/LoadingSvg";
import { useAuth } from "../hooks/auth";

const Login = () => {
	const [searchParams] = useSearchParams();
	const redirectTo = searchParams.get("redirect_to");

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [errors, setErrors] = useState();
	const [shouldRemember, setShouldRemember] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const { login } = useAuth({
		middleware: "guest",
		redirectIfAuthenticated: redirectTo ? redirectTo : "/records",
	});

	const handleLogin = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		await login({ email, password, remember: shouldRemember, setErrors });
		setPassword("");
		setIsLoading(false);
	};

	return (
		<>
			<section className='flex justify-center items-center h-screen bg-gray-100 px-3'>
				<form
					onSubmit={handleLogin}
					method='POST'
					className='max-w-md w-full bg-white rounded p-6 space-y-4'>
					<div className='mb-4'>
						<h2 className='text-xl font-bold'>Sign In</h2>
					</div>

					{errors && (
						<p className='alert bg-orange-200 border border-orange-300 px-4 py-3 rounded text-xs font-semibold'>
							{errors}
						</p>
					)}

					<div>
						<input
							className='w-full p-4 text-sm bg-gray-50 focus:outline-none border border-gray-200 rounded text-gray-600'
							type='email'
							placeholder='Email'
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>
					<div>
						<input
							className='w-full p-4 text-sm bg-gray-50 focus:outline-none border border-gray-200 rounded text-gray-600'
							type='password'
							placeholder='Password'
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</div>
					<div>
						{!isLoading && (
							<button
								type='submit'
								className='w-full py-4 bg-blue-600 hover:bg-blue-700 rounded text-sm font-bold text-gray-50 transition duration-200'>
								Sign In
							</button>
						)}

						{isLoading && (
							<button
								type='button'
								className='w-full py-4 bg-blue-600 hover:bg-blue-700 rounded text-sm font-bold text-gray-50 transition duration-200 '>
								<LoadingSvg />
							</button>
						)}
					</div>
					<div className='flex items-center justify-between'>
						<div className='flex flex-row items-center'>
							<input
								type='checkbox'
								id='remember-me'
								onChange={() => setShouldRemember(!shouldRemember)}
								className='focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded'
							/>
							<label
								htmlFor='remember-me'
								className='ml-2 text-sm font-normal text-gray-600'>
								Remember me
							</label>
						</div>
						<div>
							<Link
								to='/forgot-password'
								className='text-sm text-blue-600 hover:underline'>
								Forgot password?
							</Link>
						</div>
					</div>
				</form>
			</section>
		</>
	);
};

export default Login;
