import Select from "react-select";
import { useEffect, useState } from "react";
import { apiGet } from "../../inc/ApiHelpers";

const RecordFilter = ({
	handleFilterSearch,
	statuses,
	setIsSearch,
	isSearch,
}) => {
	const defaultFilterData = {
		query: "",
		fromDate: "",
		toDate: "",
		status: "",
	};

	const [defaultStatus, setDefaultStatus] = useState([]);

	const [filterData, setFilterData] = useState(defaultFilterData);

	const resetFilterSearch = () => {
		// turn off search mode.
		setIsSearch(false);

		// reset input fields.
		setFilterData(defaultFilterData);

		const resetStatus = getDefaultStatusValues(statuses, defaultStatus);

		// reset selected status.
		handleOnChange("status", resetStatus);

		//  trigger form submit.
		handleFilterSearch({
			...filterData,
			status: resetStatus,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		// turn on search mode.
		setIsSearch(true);

		handleFilterSearch(filterData);
	};

	const handleOnChange = (key, val) => {
		setFilterData((prevState) => ({ ...prevState, [key]: val }));
	};

	const getDefaultStatusValues = (statuses, defaultStatus) => {
		return statuses.map((status) =>
			defaultStatus.includes(status.id)
				? {
						label: status.title,
						value: status.id,
				  }
				: ""
		);
	};

	useEffect(() => {
		apiGet("/api/get-organization-settings").then((res) => {
			// we need this to reset filter data.
			setDefaultStatus(res);

			handleOnChange("status", getDefaultStatusValues(statuses, res));
		});
	}, []);

	return (
		<form
			onSubmit={handleSubmit}
			method='POST'
			className='filter border bg-white p-2 text-xs flex justify-content-between items-center print:hidden'>
			<div className='flex-1 flex flex-col md:flex-row'>
				<div className='flex mb-2 md:mb-0 flex-1 max-w-sm'>
					<input
						type='text'
						value={filterData.query}
						placeholder='Search'
						className='p-2 mr-1 flex-1 border '
						onChange={(e) => handleOnChange("query", e.target.value)}
					/>

					<button
						type='submit'
						className=' bg-indigo-200 p-2 px-3 rounded-sm border border-indigo-300 font-semibold md:mr-6'>
						Submit
					</button>

					{isSearch && (
						// for .md only
						<button
							type='button'
							title='Reset Search searchQuery'
							onClick={resetFilterSearch}
							className='hidden md:ml-[-20px] md:block lg:hidden ml-1 mr-6 bg-gray-200 hover:bg-gray-400 p-2 px-3 rounded-sm border border-gray-300 hover:border-gray-400 hover:text-white font-semibold '>
							Reset
						</button>
					)}
				</div>

				<div className='flex justify-between mb-2 md:mb-0 '>
					<div className='md:mr-4 lg:mr-6'>
						<label htmlFor='from-date' className='font-semibold mr-2'>
							From
						</label>
						<input
							type='date'
							id='from-date'
							value={filterData.fromDate}
							className='p-2 border h-[38px]'
							onChange={(e) => handleOnChange("fromDate", e.target.value)}
						/>
					</div>

					<div className='md:mr-4 lg:mr-6'>
						<label htmlFor='from-date' className='font-semibold  mr-2'>
							To
						</label>
						<input
							type='date'
							id='to-date'
							value={filterData.toDate}
							className='p-2 border h-[38px]'
							onChange={(e) => handleOnChange("toDate", e.target.value)}
						/>
					</div>
				</div>

				<div className='flex'>
					<div className='lg:mr-6 flex items-center'>
						<label htmlFor='status' className='font-semibold mr-2'>
							Status
						</label>

						{filterData.status && (
							<Select
								cacheOptions
								styles={{
									control: (provided) => ({
										...provided,
										borderRadius: "0px",
										borderColor: "#d8d8d8",
									}),
								}}
								options={statuses.map((item) => ({
									label: item.title,
									value: item.id,
								}))}
								value={filterData.status}
								isMulti={true}
								onChange={(selOption) => {
									handleOnChange("status", selOption);
								}}
							/>
						)}
					</div>

					{isSearch && (
						// for mobile devices
						<button
							type='button'
							title='Reset Search searchQuery'
							onClick={resetFilterSearch}
							className='md:hidden ml-2 bg-gray-200 hover:bg-gray-400 p-2 px-3 rounded-sm border border-gray-300 hover:border-gray-400 hover:text-white font-semibold '>
							Reset
						</button>
					)}
				</div>
			</div>

			{isSearch && (
				// for larger device
				<button
					type='button'
					title='Reset Search searchQuery'
					onClick={resetFilterSearch}
					className='hidden lg:block ml-2 bg-gray-200 hover:bg-gray-400 p-2 px-3 rounded-sm border border-gray-300 hover:border-gray-400 hover:text-white font-semibold '>
					Reset
				</button>
			)}
		</form>
	);
};

export default RecordFilter;
