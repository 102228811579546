import { useEffect, useRef, useState } from "react";
import RightModal from "../components/RightModal";
import FormGroup from "../components/FormGroup";
import { useGlobalContext } from "../contexts/GlobalContext";

import { apiPatch, apiPost } from "../inc/ApiHelpers";
import { getPostsPerPage } from "../inc/Helpers";

function NewUserForm({
	isVisible,
	setData,
	resetForm,
	defaultFormData,
	formFields,
}) {
	const modalRef = useRef(null);

	const {
		setIsPageLoading,
		updateAnimationState,
		paginationData,
		setShowNewFormModal,
		setToast,
	} = useGlobalContext();

	const [errors, setErrors] = useState();
	const [formData, setFormData] = useState(defaultFormData);

	// update form data on state change.
	useEffect(() => {
		setFormData(defaultFormData);
	}, [defaultFormData]);

	// helper function to easily update state for form fields.
	const updateFormStates = (key, val) => {
		setFormData((prev) => ({ ...prev, [key]: val }));
	};

	// -----------------------------
	// 	HANDLE FORM SUBMIT
	// -----------------------------
	const handleFormSubmit = async (e) => {
		e.preventDefault();
		setErrors("");

		formData["role_id"] = formData.user_role;

		const limitTotalPosts = getPostsPerPage(paginationData);

		setIsPageLoading(true);

		// api request.
		try {
			let newData;
			if (formData?.id) {
				// ------------------
				// edit.
				// ------------------

				newData = await apiPatch({
					url: "/api/users/" + formData.id,
					...formData,
				});

				updateAnimationState({
					id: formData.id,
					action: "edit",
					callback: () =>
						setData((prev) =>
							prev.map((item) => (item.id === formData.id ? newData : item))
						),
				});
			} else {
				// ------------------
				// new post.
				// ------------------
				newData = await apiPost({
					url: "/api/users",
					...formData,
				});

				updateAnimationState({
					id: newData.id,
					action: "edit",
					callback: () => {
						// update data
						setData((prev) => [newData, ...prev].splice(0, limitTotalPosts));
					},
				});
			}
			setShowNewFormModal(false);
		} catch (e) {
			if (e.response.status === 500) {
				setToast(e.response.data.message, "danger");
			} else {
				setErrors(e.response.data.errors);
			}
		}

		setIsPageLoading(false);
	};

	return (
		<>
			<RightModal
				errors={errors}
				setErrors={setErrors}
				handleSubmit={handleFormSubmit}
				ref={modalRef}
				isVisible={isVisible}
				isEdit={defaultFormData?.id ? true : false}
				resetFormData={resetForm}>
				{formFields.map((item) => (
					<FormGroup
						key={item.id}
						onChange={updateFormStates}
						item={item}
						errors={errors}
						defaultFormData={defaultFormData}
						seloption={{}}
						value={formData[item.id]}
					/>
				))}
			</RightModal>
		</>
	);
}

export default NewUserForm;
