import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/auth";
const PrivateRoutes = () => {
	const { user } = useAuth({ middleware: "auth" });
	let location = useLocation();
	return user ? (
		<Outlet />
	) : (
		<Navigate to={"/?redirect_to=" + location.pathname} />
	);
};

export default PrivateRoutes;
