import Select from "react-select";

const ReactSelect = ({ selvalue, options, id, onChange, multi, ...props }) => {
	const customStyles = {
		control: (provided) => ({
			...provided,
			borderRadius: "0px",
			borderColor: "#d8d8d8",
		}),
	};

	return (
		<Select
			cacheOptions
			styles={customStyles}
			options={options}
			defaultValue={selvalue}
			isMulti={Boolean(multi)}
			onChange={(selOption) => {
				onChange(
					props.data.id,
					Boolean(multi) ? selOption.map((item) => item.value) : selOption.value
				);
			}}
		/>
	);
};

export default ReactSelect;
