import "animate.css";

import { Routes, Route } from "react-router-dom";

// import NotFound from "./pages/NotFound";
// import Header from "./components/Header";
// import RouteLoading from "./components/RouteLoading";
import PrivateRoutes from "./components/PrivateRoutes";

// const Status = React.lazy(() => import("./pages/Status"));
// const Settings = React.lazy(() => import("./pages/Settings"));
// const Purchases = React.lazy(() => import("./pages/Purchases"));
// const ForgotPassword = React.lazy(() => import("./pages/ForgotPassword"));
// const Users = React.lazy(() => import("./pages/Users"));
// const Jobsheet = React.lazy(() => import("./pages/print/Jobsheet"));
// const PasswordReset = React.lazy(() => import("./pages/PasswordReset"));
// const Records = React.lazy(() => import("./pages/Records"));

import Login from "./pages/Login";
import Records from "./pages/Records";
import Settings from "./pages/Settings";
import Purchases from "./pages/Purchases";
import ForgotPassword from "./pages/ForgotPassword";
import PasswordReset from "./pages/PasswordReset";
import NotFound from "./pages/NotFound";
import Users from "./pages/Users";
import Jobsheet from "./pages/print/Jobsheet";
import Status from "./pages/Status";

function App() {
	return (
		<>
			<Routes>
				<Route path='/' exact element={<Login />} />
				<Route path='/forgot-password' exact element={<ForgotPassword />} />
				<Route path='/password-reset/*' element={<PasswordReset />} />
				<Route path='*' element={<NotFound />} />

				{/* protected routes */}
				<Route element={<PrivateRoutes />}>
					<Route
						path='/print-jobsheet/:recordId'
						exact
						element={<Jobsheet />}
					/>
					<Route path='/records' exact element={<Records />} />
					<Route path='/status' element={<Status />} />
					<Route path='/purchases' element={<Purchases />} />
					<Route path='/users' element={<Users />} />
					<Route path='/settings' element={<Settings />} />
				</Route>
			</Routes>
		</>
	);
}

export default App;
