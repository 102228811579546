import { useEffect, useRef, useState } from "react";
import RightModal from "../components/RightModal";
import FormGroup from "../components/FormGroup";
import { useGlobalContext } from "../contexts/GlobalContext";

import { apiPatch, apiPost } from "../inc/ApiHelpers";
import { getPostsPerPage } from "../inc/Helpers";

function NewPurchaseForm({ isVisible, setData, resetForm, defaultFormData }) {
	const modalRef = useRef(null);

	const {
		setIsPageLoading,
		updateAnimationState,
		paginationData,
		setShowNewFormModal,
		defaultBrandSuggestions,
		defaultModelSuggestions,
	} = useGlobalContext();

	const [errors, setErrors] = useState();
	const [formData, setFormData] = useState(defaultFormData);

	// update form data on state change.
	useEffect(() => {
		setFormData(defaultFormData);
	}, [defaultFormData]);

	const updateFormStates = (key, val) => {
		setFormData(() => ({ ...formData, [key]: val }));
	};

	// create new or update old records.
	const handleFormSubmit = async (e) => {
		e.preventDefault();
		setErrors("");

		const limitTotalPosts = getPostsPerPage(paginationData);

		setIsPageLoading(true);

		try {
			if (formData?.id) {
				// -----------------------------
				//  edit.
				// -----------------------------

				const newData = await apiPatch({
					url: "/api/purchases/" + formData.id,
					...formData,
				});

				updateAnimationState({
					id: formData.id,
					action: "edit",
					callback: () => {
						// update data
						setData((prev) =>
							// replace selected row with new data.
							prev.map((item) => (item.id === formData.id ? newData : item))
						);
					},
				});
			} else {
				// -----------------------------
				// new post.
				// -----------------------------

				const newData = await apiPost({
					url: "/api/purchases",
					...formData,
				});

				updateAnimationState({
					id: newData.id,
					action: "new",
					callback: () => {
						// update data
						// limit total posts.
						setData((prev) => [newData, ...prev].splice(0, limitTotalPosts));
					},
				});
			}
			setShowNewFormModal(false);
		} catch (e) {
			setErrors(e.response.data.errors);
		}

		setIsPageLoading(false);
	};

	const handleBrandOnChange = (text) => {
		if (!text) {
			return [];
		}
		return defaultBrandSuggestions
			.filter(
				(suggestion) =>
					suggestion.toLowerCase().indexOf(text.toLowerCase()) > -1
			)
			.slice(0, 8);
	};

	const handleModelOnChange = async (text) => {
		if (!text) {
			return [];
		}
		return defaultModelSuggestions
			.filter(
				(suggestion) =>
					suggestion.toLowerCase().indexOf(text.toLowerCase()) > -1
			)
			.slice(0, 8);
	};

	return (
		<>
			<RightModal
				errors={errors}
				setErrors={setErrors}
				handleSubmit={handleFormSubmit}
				ref={modalRef}
				isVisible={isVisible}
				isEdit={defaultFormData?.id ? true : false}
				resetFormData={resetForm}>
				{[
					{ id: "purchase_date", type: "date", required: 1 },
					{
						id: "brand",
						type: "autocomplete",
						required: 1,
						suggestions: [],
						autocompleteOnChange: handleBrandOnChange,
					},

					{
						id: "model",
						type: "autocomplete",
						required: 1,
						autocompleteOnChange: handleModelOnChange,
						suggestion: [],
					},
					{ id: "item", type: "text", required: 1 },
					{ id: "rate", type: "number", min: 0, step: 0.1, required: 1 },
					{ id: "shop", type: "text", required: 1 },
					{ id: "paid_date", type: "date" },
					{ id: "payment_method", type: "text" },
					{ id: "id", type: "hidden" },
				].map((item) => (
					<FormGroup
						key={item.id}
						onChange={updateFormStates}
						item={item}
						errors={errors}
						value={formData[item.id]}
					/>
				))}
			</RightModal>
		</>
	);
}

export default NewPurchaseForm;
